//---------------------------------------------------------------
// GRID & LAYOUT SETTINGS
//---------------------------------------------------------------

// Grid specific:
$grid: 12;
$gutter: 20px;
$gutter_sm: 20px;
$gutter_md: 20px;
$gutter_lg: 20px;
$gutter_xlg: 20px;
$gutter_xxlg: 20px;


// Grid & Layout breakpoints:
$container-max-width: 1360px;
$container-xlg: 1200px;
$container-lg: 960px;
$container-md: 650px;


$xs: 479px;
$sm: 480px;
$md: 768px;
$lg: 1024px;
$xlg: 1400px;
$xxlg: 1600px;


// Širina ekrana na kojoj se preslaguju column_content i column_sidebar
$column_break: 768px;


//---------------------------------------------------------------
// FONTS
//---------------------------------------------------------------
$font_prim: 'Work Sans', sans-serif;
$font_sec: 'Playfair Display', serif;

$base-fontSize: 62.5%;
$base-lineHeight: 1.5;


// Font weights
// $thin: 100;
// $ultra_light: 200;
// $light: 300;
$normal: 400;
// $medium: 500;
// $semi_bold: 600;
$bold: 700;
// $extra_bold: 800;
// $ultra_bold: 900;




//---------------------------------------------------------------
// COLORS
//---------------------------------------------------------------
//font colors

// plava 1
$color_01: #183242;
$blue_1: #183242;

// plava 2
$color_02: #3878a1;
$blue_2: #3878a1;

//plava 3
$color_03: #469fd7;
$blue_3: #469fd7;

// krem 1
$color_04: #f8f7f1;
$yellow_1: #f8f7f1;

// krem 2
$color_05: #e8e5cc;
$yellow_2: #e8e5cc;

// krem 3
$color_06: #c8c7b9;
$yellow_3: #c8c7b9;


// Text
$color_07: #6a6a62;

// Subfold bg
$color_08: #6b6a63;


//Social networks colors
$color_facebook: #3b5998;
$color_twitter: #00b6f1;
$color_youtube: #b31217;
$color_instagram: #c32aa3;
$color_instagram: #c32aa3;
$color_pinterest: #cb2027;
$color_googleplus: #df4a32;
$color_linkedin: #007bb6;
$color_vimeo: #45bbff;



//---------------------------------------------------------------
// COLOR CODING, c3: icon code
//---------------------------------------------------------------
// $color_list: (

//     type: (
//         agrovijesti:    ( c1: #e37a09, c2: #a3238e, c3: "\f005"  ),
//         opg-tjedna:     ( c1: #71ad1c, c2: #f99d1c, c3: "\f182"  ),
//         globalno-selo:  ( c1: #dbb726, c2: #cd001a, c3: "\f004"  ),
//         agrosavjeti:    ( c1: #3e7e0a, c2: #00aeef, c3: "\f135"  ),
//         eu-fondovi:     ( c1: #1ca8ad, c2: #00aeef, c3: "\f135"  ),
//         zakoni:         ( c1: #b37430, c2: #00aeef, c3: "\f135"  ),
//         vijesti:        ( c1: #b37430, c2: #00aeef, c3: "\f135"  ),
//         sport:          ( c1: $color_02, c2: #a3238e, c3: "\f005"  ),
//         kultura:        ( c1: #dbb726, c2: #cd001a, c3: "\f004"  ),
//     )
// );

// $types: map-get($color_list, type);
// @each $type, $specs in $types {
//     $c1: map-get($specs, c1);
//     $c2: map-get($specs, c2);
//     .class--#{$type} {
//         color: $c1;
//         border-color: $c2;
//     }
// }